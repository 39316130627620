const $ = require("jquery");

function postFeedback(url, data, success, error) {
    $.ajax({
        url: url,
        type: "POST",
        data: data,
        dataType: 'json',
        success: success,
        error: error
    });
}

export default postFeedback;